import styled from 'styled-components'

interface Props {
    isError: string;
}

export const ContainerInput = styled.div`
    padding: 0.5rem;
    background: #ffffff;
    border: 2px solid black;
    height: 100vh;
    display: flex;
    justify-content: center;
`

export const InputField = styled.input<Props>`
  width: 100%;
  overflow-y: hidden;
  padding-left: 0.57rem;
  border: none;
  outline: none;

    &::placeholder {
        color: ${props => props.isError ? 'red' : 'inherit'};
    }
`
