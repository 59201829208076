// General
console.log('Dev ver "edit sessions"')

const env = import.meta.env

// Mixpanel
// const MIXPANEL_TOKEN_LEO_DEV = '297bc56853b7e96b7e476a08018e54e2'
export const MIXPANEL_TOKEN = env.VITE_MIXPANEL_TOKEN || '297bc56853b7e96b7e476a08018e54e2'

//SENTRY DEV
// export const SENTRY_DSN =
//   "https://831a0eb73e43689a46ac8b790c73a72e@o4505905649090560.ingest.sentry.io/4505905746477056";
// export const SENTRY_ENVIRONMENT = "develop";

export const SENTRY_DSN = env.VITE_SENTRY_DSN
export const SENTRY_ENVIRONMENT = env.VITE_SENTRY_ENVIRONMENT

export const BASE_URL = env.VITE_API_ENDPOINT || 'https://api.getleo.ai'

// export const BASE_URL = env.VITE_API_ENDPOINT || 'http://0.0.0.0:8000'

export const WEBSITE_URL = 'https://www.getleo.ai/'

export const PRIVACY_POLICY_URL = 'https://www.getleo.ai/privacy'

export const SLACK_URL = 'https://join.slack.com/t/leoaipublic/shared_invite/zt-25ktda6yb-xJA~q5wf1hP3bAfNCFus9Q'

export const SUPPORT_URL = 'support@getleo.ai'
