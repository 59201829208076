// General

const env = import.meta.env

export const DEVELOPMENT = env.REACT_APP_DEVELOPMENT === 'true' || false

// Mixpanel
const MIXPANEL_TOKEN_LEO_DEV = '297bc56853b7e96b7e476a08018e54e2'
export const MIXPANEL_TOKEN = env.REACT_APP_MIXPANEL_TOKEN || MIXPANEL_TOKEN_LEO_DEV

// Sentry DSN
// https://831a0eb73e43689a46ac8b790c73a72e@o4505905649090560.ingest.sentry.io/4505905746477056
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN

export const SENTRY_ENVIRONMENT = env.REACT_APP_SENTRY_ENVIRONMENT || 'develop'

// Don't export this so we know for a fact that no one uses it.
const BASE_URL = env.REACT_APP_API_BASE_URL || env.VITE_API_ENDPOINT || 'https://api.getleo.ai'

export const POLLING_TIMEOUT = 600000
export const POLLING_INTERVAL = 5000

// Export each endpoint url separately - Please keep it that way.
// Reasons being that we sometimes want to change host for some endpoints,
// Either in the future or in the present when developing.
// TODO: Please, let's move to react-query for example.
export const LEO_SPEC_ENDPOINT = `${BASE_URL}/api/v2/spec`
export const LEO_SPEC_EXPORT_ENDPOINT = `${BASE_URL}/api/v2/spec/export`
export const KYC_ENDPOINT = `${BASE_URL}/api/v1/user/kyc`
export const GET_ACCOUNT_CREDITS = `${BASE_URL}/api/v1/user/credits`
export const SESSION_EXPORT_ENDPOINT = `${BASE_URL}/api/v1/session/export`
export const IMAGE_SHARE_ENDPOINT = `${BASE_URL}/api/v1/image/share`
export const TALK_TO_US = `${BASE_URL}/api/v1/user/talk-to-us`

// V3
export const IMAGE_LIST_ENDPOINT = `${BASE_URL}/api/v3/image`
export const CREATE_SESSION_ENDPOINT = `${BASE_URL}/api/v3/session`
export const LIST_SESSION_ENDPOINT = `${BASE_URL}/api/v3/session`
export const CREATE_REVISION_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision`
export const LEO_CHAT_ENDPOINT = `${BASE_URL}/api/v4/session/:sessionNum/revision/:revisionNum/chat`
export const TEXT_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/text-to-image`
export const SKETCH_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/sketch-to-image`
export const UPLOAD_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/upload-to-image`
export const VARIATION_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/variation`
export const IMAGE_ERASE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/vary-region`
export const PROJECT_DOCS = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/project-document/:document`

// this is the aspect ratio which being used to ask AR from midjourney, not being used currently when generating with cadgen.
export const GENERATE_IMAGE_ASPECT_RATIO = env.REACT_APP_GENERATE_IMAGE_ASPECT_RATIO || '5:4'

// this is the aspect ratio being used for the magic eraser feature. not changing the generated aspect ratio which is being setup in th ebackend
export const MASK_IMAGE_ASPECT_RATIO = env.REACT_APP_MASK_IMAGE_ASPECT_RATIO || 1152 / 832

// TODO: better create a hook such as useFeatures
export const FEATURE_PROFILE_PAGE = env.REACT_APP_FEATURE_PROFILE_PAGE === 'true' || false
export const FEATURE_SHARE_IMAGE = env.REACT_APP_FEATURE_SHARE_IMAGE === 'true' || true
export const FEATURE_SPEC_EXPORT = env.REACT_APP_FEATURE_SPEC_EXPORT === 'true' || true
export const FEATURE_MAGIC_ERASER = env.REACT_APP_FEATURE_MAGIC_ERASER === 'true' || true
export const FEATURE_PRODUCT_SUMMARY = env.REACT_APP_FEATURE_PRODUCT_SUMMARY === 'true' || true
export const FEATURE_VIEW_PROJECT_DOCS = env.REACT_APP_FEATURE_VIEW_PROJECT_DOCS === 'true' || true
export const FEATURE_TECH_SPEC = env.REACT_APP_FEATURE_TECH_SPEC === 'true' || true
export const FEATURE_BOM = env.REACT_APP_FEATURE_BOM === 'true' || false
export const FEATURE_GANTT = env.REACT_APP_FEATURE_GANTT === 'true' || false
export const FEATURE_COST = env.REACT_APP_FEATURE_COST === 'true' || false
export const FEATURE_NO_GENERATE = env.REACT_APP_FEATURE_NO_GENERATE === 'true' || false
export const FEATURE_SESSIONS_PAGE = env.REACT_APP_FEATURE_SESSIONS_PAGE === 'true' || true
export const INTRODUCTORY_POP_UP = `${BASE_URL}/api/v1/popup`
