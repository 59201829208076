import { useState } from 'react'
import { submitInput } from '@/lib/app.ts'
import { ChatButton } from '@/components/chatSection/chatButton/ChatButton.tsx'
import { ContainerInput, InputField } from '@/components/inputWindow/InputWindowStyle.ts'

const InputWindow = () => {
  const [input, setInput] = useState('')
    const [error, setError] = useState('')

    const handleSubmit = () => {
        if (input.trim() === '') {
            setError('Description is required')
        } else {
            setError('')
            submitInput(input)
            setInput('')
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
        if (e.target.value.trim() !== '') {
            setError('')
        }
    }

  return (
    <ContainerInput >
      <InputField isError={error} required type="text" value={input} onChange={handleInputChange} placeholder="Type here..." />
      <ChatButton {...{ onClick: handleSubmit }} />
    </ContainerInput>
  )
}
export default InputWindow
